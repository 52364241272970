import React, { ChangeEvent } from "react";
import { getAddress, signTransaction, signMessage } from "sats-connect";

interface WalletConnectState {
  paymentAddress: string;
  ordinalsAddress: string;
  ordvJson: string;
  message: string;
}

class WalletConnect extends React.Component<{}, WalletConnectState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      paymentAddress: "",
      ordinalsAddress: "",
      ordvJson: "",
      message: "",
    };
  }

  onConnectClick = async () => {
    const getAddressOptions = {
      payload: {
        purposes: ["ordinals", "payment"],
        message: "Address for receiving Ordinals",
        network: {
          type: "Mainnet",
        },
      },
      onFinish: (response: any) => {
        this.setState({
          ordinalsAddress: response.addresses[0].address,
          paymentAddress: response.addresses[1].address,
        });
      },
      onCancel: () => alert("Request canceled"),
    };
    await getAddress(getAddressOptions);
  };

  onSignTransactionClick = async () => {
    const signPsbtOptions = {
      payload: {
        network: {
          type: "Testnet",
        },
        message: "Sign Transaction",
        psbtBase64: `...`,
        broadcast: false,
        inputsToSign: [
          {
            address: this.state.ordinalsAddress,
            signingIndexes: [0],
          },
        ],
      },
      onFinish: (response: any) => {
        alert(response.psbtBase64);
      },
      onCancel: () => alert("Canceled"),
    };
    await signTransaction(signPsbtOptions);
  };

  onSignMessageClick = async () => {
    const { ordinalsAddress, message } = this.state;

    const signMessageOptions = {
      payload: {
        network: {
          type: "Mainnet",
        },
        address: ordinalsAddress,
        message: message,
      },
      onFinish: (response: any) => {
        const ordvJson = JSON.stringify(
          {
            p: "ordv",
            m: message,
            s: response,
          },
          null,
          2
        );
        this.setState({ ordvJson });
      },
      onCancel: () => alert("Canceled"),
    };

    await signMessage(signMessageOptions);
  };

  onMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ message: event.target.value });
  };

  render() {
    return (
      <div className="wallet-connect-container">
        <h1>Generate ordv text</h1>
        <div className="address-container">
          {this.state.ordinalsAddress && (
            <div className="address-text">
              Ordinals Address: {this.state.ordinalsAddress}
            </div>
          )}

          <div className="connect-button-container">
            <button className="connect-button" onClick={this.onConnectClick}>
              Connect (Xverse)
            </button>
          </div>

          <div className="sign-message-container">
            <textarea
              className="message-input"
              placeholder="Enter Message"
              value={this.state.message}
              onChange={this.onMessageChange}
              rows={4}
            />
            <button
              className="sign-message-button"
              onClick={this.onSignMessageClick}
            >
              Sign message
            </button>
          </div>
          <div className="ordv-json-container">
            {this.state.ordvJson && (
              <>
                <h2>Generated Ordv JSON:</h2>
                <pre className="ordv-json-text">{this.state.ordvJson}</pre>
                <button
                  className="copy-button"
                  onClick={() =>
                    navigator.clipboard.writeText(this.state.ordvJson)
                  }
                >
                  Copy to Clipboard
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default WalletConnect;

         
