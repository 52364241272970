import React, { useState } from 'react';
import './App.css';
import ReCAPTCHA from 'react-google-recaptcha';
import logoLock from './lock.png';
import WalletConnect from './WalletConnect';

const VerifyForm: React.FC = () => {
  const [address, setAddress] = useState('');
  const [message, setMessage] = useState('');
  const [signature, setSignature] = useState('');
  const [result, setResult] = useState<{isValid: boolean, message: string} | null>(null);
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const [captchaError, setCaptchaError] = useState<string | null>(null);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!captchaValue) {
      alert('Please verify the captcha!');
      return;
    }

    setIsFormSubmitted(true);

    const response = await fetch('https://ordv-backend-wjcejukisq-uc.a.run.app/api/verify', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ address, message, signature, captcha: captchaValue }),
    });

    const data = await response.json();
    setResult(data); // Update the state with the result

    if (!data.isValid) {
      setCaptchaError('Invalid captcha. Please try again.');
    }
  };
  const handleCaptchaChange = (value: string | null) => {
    setCaptchaValue(value);
  };

  return (
    <>
      <form className="verify-form" onSubmit={handleSubmit}>
        <input type="text" placeholder="Address" className="form-input" value={address} onChange={e => setAddress(e.target.value)} />
        <input type="text" placeholder="Message" className="form-input" value={message} onChange={e => setMessage(e.target.value)} />
        <input type="text" placeholder="Signature" className="form-input" value={signature} onChange={e => setSignature(e.target.value)} />
        <ReCAPTCHA
          sitekey="6LfUVv8lAAAAAJrXg18z7lXWdz-gaeWbbpbcwGDR" // Replace this with your site key
          onChange={handleCaptchaChange}
        />
        <button type="submit" className="verify-button">Verify</button>
      </form>

      {result && (
  <div>
    <p className={`address-text ${result.isValid ? 'valid' : 'invalid'}`}>Address: {address}</p>
    <p style={{color: result.isValid ? 'green' : 'red'}}>Message: {result.message}</p>
  </div>
)}

{isFormSubmitted && captchaValue && result === null && (
        <p className="error-message">{captchaError}</p>
)}
    </>
  );
}


const App: React.FC = () => {
  return (
    <div className="App">
      <header className="App-header">
        <nav>
          <h1 className="logo">
            <img src={logoLock} alt="Logo Lock" className="logo-lock" />
            <svg className="App-logo" viewBox="0 0 100 20" xmlns="http://www.w3.org/2000/svg" fill="#333">
              <text x="0" y="20" fontFamily="'Roboto', sans-serif" fontSize="20">
                ordv.me
              </text>
            </svg>
          </h1>
        </nav>
        <p></p>
      </header>

      <main className="App-main">
        <section className="About-section">
          <h2>BIP-322 Bitcoin Ordinals Verification Protocol</h2>
          <p>
            Ordv.me is a platform that utilizes BIP-322 signatures and Bitcoin's blockchain to manage your decentralized online identity.
          </p>
        </section>

        <section className="Features-section">
          <h2>Features</h2>
          <p>
            With ordv.me, you can inscribe signed messages on Bitcoin via the Ordinals Protocol. These tokens, along with your message signature, give you access to a wide range of verification systems.
          </p>
        </section>

        <section className="Protocol-section">
          <h2>Our Protocol Spec</h2>
          <pre className="center-pre">
            {`{
  "p": "ordv",
  "m": "message text",
  "s": "BIP-322 signature"
}`}
          </pre>
          <p className="text">
            Our API is now running and available in beta.
          </p>
        </section>

        <section className="Verification-section">
          <h2>Verify your BIP-322 Message</h2>
          <p className="text">
            This feature uses the ordv.me BIP-322 API to validate messages. 
            </p>
          <VerifyForm />
        </section>

        <WalletConnect />

      </main>

      <footer className="App-footer">
        <p className="small-text">Powered by BTC Machine #98</p>
      </footer>
    </div>
  );
}

export default App;